import { TypedRoutes } from './shared/model/typed-route.model';
import { inject } from '@angular/core';
import { QuillLoaderService } from './release-notes/quill-loader.service';
import { AuthStore } from './core/auth/auth.store';
import { setTitle } from './core/language/set-title';
import { hasRouteAuthority, isLoggedIn, isLoggedOut } from './core/auth/route.guard';

export const appRoutes: TypedRoutes = [
    {
        path: 'reset/finish',
        canMatch: [isLoggedOut],
        loadComponent: () => import('./account/password-reset/finish/password-reset-finish.component'),
        title: setTitle('global.menu.account.password')
    },
    {
        path: 'activate-user',
        canMatch: [isLoggedOut],
        loadComponent: () => import('./account/activate-user/activate-user.component'),
        title: setTitle('register.title')
    },
    {
        path: '',
        pathMatch: 'full',
        canMatch: [isLoggedOut],
        loadComponent: () => import('./login-wrapper/login-wrapper.component'),
        title: setTitle('global.title')
    },
    {
        path: '',
        canMatch: [isLoggedIn],
        loadComponent: () => import('./layouts/main/main.component'),
        children: [
            {
                path: '',
                pathMatch: 'full',
                canMatch: [() => !inject(AuthStore).getTenantToken()],
                loadComponent: () => import('./layouts/empty.component')
            },
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('./home/home.component'),
                title: setTitle('pageTitle.dashboard')
            },
            {
                path: 'new-tasks',
                canActivate: [hasRouteAuthority('NEW_TASKS')],
                loadComponent: () => import('./new-tasks/new-tasks-main/new-tasks-main.component'),
                title: setTitle('pageTitle.newTasks')
            },
            {
                path: 'gis-works',
                canActivate: [hasRouteAuthority('GIS_WORKS_NAVIGATOR')],
                loadComponent: () => import('./geo-map/geo-map-wrapper'),
                title: setTitle('pageTitle.geoMap')
            },
            {
                path: 'control-panel',
                loadChildren: () => import('./control-panel/control-panel.routes')
            },
            {
                path: 'monitoring',
                loadChildren: () => import('./monitoring/monitoring.routes')
            },

            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.routes')
            },
            {
                path: 'work-scheduler',
                canActivate: [hasRouteAuthority('SCHEDULER')],
                loadComponent: () => import('./work-scheduler/work-scheduler.component'),
                title: setTitle('work-scheduler.workScheduler')
            },
            {
                path: 'time-validation',
                canActivate: [hasRouteAuthority('TIME_VALIDATION')],
                loadComponent: () => import('./time-validation/time-validation.component'),
                title: setTitle('pageTitle.timeValidation')
            },
            {
                path: 'materials-validation',
                canActivate: [hasRouteAuthority('CONTROL_PANEL')],
                loadComponent: () => import('./materials-validation/materials-validation-wrapper.component'),
                title: setTitle('pageTitle.materialsValidation')
            },
            {
                path: 'time-tracking',
                canActivate: [hasRouteAuthority('TIME_TRACKING')],
                loadComponent: () => import('./time-tracking/time-tracking.component'),
                title: setTitle('pageTitle.timeTracking')
            },
            {
                path: 'video-streaming',
                loadChildren: () => import('./video-streaming/video-streaming.routes')
            },
            {
                path: 'users',
                loadChildren: () => import('./user-administration/user-admin.routes')
            },
            {
                path: 'administration-project',
                loadChildren: () => import('./project-administration/project-admin.routes')
            },
            {
                path: 'template-management',
                loadChildren: () => import('./template-administration/template-admin.routes')
            },
            {
                path: 'xlsx-template-management',
                canActivate: [hasRouteAuthority('ADMINISTRATION_PDF')],
                loadComponent: () => import('./manage-template/manage-xlsx-template/manage-xlsx-template.component')
                // title: setTitle('pageTitle.administrationTemplates')
            },
            {
                path: 'xslt',
                canActivate: [hasRouteAuthority('ADMINISTRATION_PDF')],
                loadComponent: () => import('./project-administration/xslt-reader/xslt-reader.component'),
                title: setTitle('pageTitle.administrationPDFTemplates')
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.routes')
            },
            {
                path: 'security-group',
                loadChildren: () => import('./security/security-group/security-group.routes')
            },
            {
                path: 'security-project-location',
                loadChildren: () => import('./security/security-project-location/security-project-location.routes')
            },
            {
                path: 'chat',
                canMatch: [() => inject(AuthStore).allowChatFeature()],
                loadComponent: () => import('./chat/chat-wrapper.component'),
                title: setTitle('navbar-items.chat')
            },
            {
                path: 'billing',
                loadChildren: () => import('./billing/billing.routes')
            },
            {
                path: 'audit-log',
                canActivate: [hasRouteAuthority('AUDIT_LOG')],
                loadComponent: () => import('./audit-log/audit-log-main/audit-log-main.component'),
                title: setTitle('navbar-items.audit-log')
            },
            {
                path: 'user-settings',
                loadChildren: () => import('./user-settings/user-settings.routes')
            },
            {
                path: 'release-notes/edit',
                providers: [QuillLoaderService],
                canMatch: [() => inject(QuillLoaderService).isQuillLoaded$],
                loadComponent: () => import('./release-notes/release-edit/release-edit.component'),
                title: 'Edit release notes'
            },
            {
                path: 'development',
                loadChildren: () => import('./development/development.routes')
            }
        ]
    }
];
